import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import CheckIcon from './Icons/CheckIcon'
import { cleanPath } from '../utils/path'

const TabWrap = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 422px;
	margin: 0 auto 50px;
	padding: 5px;
	background: #F6F9F9;
	border-radius: 8px;
`

const TabButton = styled.div`
	width: 50%;
	padding: 15px;
	background: ${props=>props.bg};
	border-radius: 6.36px;
	font-size: 16px;
	text-align: center;
	line-height: 1;
	color: ${props=>props.color};
	&:hover {
		cursor: pointer;
	}
	& span {
		font-size: 14px;
		font-weight: medium;
		color: ${props=>props.color};
	}
`
const ContentWrap = styled.div`
	display: ${props=>props.display};
`
const PricingGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 29px;
	@media(max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 30px;
	}
	@media(max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}
`

const PricingCard = styled.div`
	position: relative;
	height: 100%;
	padding: 20px 17px;
	border: 1px solid #213F51;
	border-radius: 3px;
	& label {
		font-size: 16px;
		color: #6F8D9E;
		font-weight: 600;
		text-transform: uppercase;
	}
	& h3 {
		font-size: 24px;
		line-height: 30px;
		margin: 10px 0;
		& span {
			font-size: 16px;
			color: #6F8D9E;
			font-weight: 400;
		}
	}
	& p {
		height: 72px;
		font-size: 16px;
		line-height: 24px;
		color: #333333;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
`

const TopLabel = styled.div`
	width: 163px;
	padding: 5px 15px;
	margin: auto;
	margin-top: -40px;
	margin-bottom: 5px;
	background: #6F8D9E;
	border-radius: 5px;
	color: white;
	text-align: center;
	text-transform: uppercase;
`
const Features = styled.div`
	min-height: 220px;
	margin: 20px 0;
	font-size: 16px;
	line-height: 30px;
	color: #333333;
	& svg {
		margin-right: 17px;
	}
`
const Button = styled.button`
	position: absolute;
	bottom: 20px;
	left: 17px;
	width: calc(100% - 34px);
	padding: 12px 8px;
	background: ${props=>props.bg};
	border: 0px;
	border-radius: 6px;
	font-size: 16px;
	text-align: center;
	color: white;
	&:focus {
		outline: none !important;
	}
	&:hover {
  	background: #6F8D9E;
    cursor: pointer;
  }
`

class PricingTab extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: "monthly"
		}
		this.visibleMonthly = this.visibleMonthly.bind(this)
		this.visibleAnnually = this.visibleAnnually.bind(this)
		this.renderFeatures = this.renderFeatures.bind(this)
	}
	visibleMonthly() {
		this.setState({
			tab: "monthly"	
		})
	}
	visibleAnnually() {
		this.setState({
			tab: "annually"
		})
	}
	renderFeatures(data) {
		return(
			<>
				{
					data.map(item=>{
						return(
							<div>
								<CheckIcon /> {item.item}
							</div>
						)
					})
				}
			</>
		)
	}
	render() {
		const { monthlyData, annuallyData } = this.props
		const { tab } = this.state
		return(
			<div>
				<TabWrap>
					<TabButton 
					  onClick={this.visibleMonthly}
					  bg={tab==="monthly" ? "#96BDD1" : "#F6F9F9"}
					  color={tab==="monthly" ? "#ffffff" : "#6F8D9E"}
					>{monthlyData.tab}
					</TabButton>
					<TabButton 
					  onClick={this.visibleAnnually}
					  bg={tab==="annually" ? "#96BDD1" : "#F6F9F9"}
					  color={tab==="annually" ? "#ffffff" : "#6F8D9E"}
					>{annuallyData.tab}<span> (15% off)</span>
					</TabButton>
				</TabWrap>
				<ContentWrap display={tab==="monthly" ? "block" : "none"}>
					<PricingGrid>
						{
							monthlyData.list.map(item=>{
								return(
									<PricingCard>
										{item.popular?(<TopLabel>Most Popular</TopLabel>):(<></>)}
										<label>{item.label}</label>
										<h3>{item.price} {item.price==="Free"?(<></>):(<span>/ month</span>)}</h3>
										<p>{item.description}</p>
										<Features>
											{this.renderFeatures(item.features)}
										</Features>
										{item.linkNewWindow?(
											<a href={cleanPath(item.buttonLink)} target="_blank" rel="noreferrer">
												<Button bg={item.buttonBg}>
													{item.buttonTxt}
												</Button>
											</a>
										):(
											<Link to={cleanPath(item.buttonLink)}>
												<Button bg={item.buttonBg}>
													{item.buttonTxt}
												</Button>
											</Link>
										)}
									</PricingCard>
								)
							})
						}
					</PricingGrid>
				</ContentWrap>
				<ContentWrap display={tab==="annually" ? "block" : "none"}>
					<PricingGrid>
						{
							annuallyData.list.map(item=>{
								return(
									<PricingCard>
										{item.popular?(<TopLabel>Most Popular</TopLabel>):(<></>)}
										<label>{item.label}</label>
										<h3>{item.price} {item.price==="Free"?(<></>):(<span>{item.period}</span>)}</h3>
										<p>{item.description}</p>
										<Features>
											{this.renderFeatures(item.features)}
										</Features>
										{item.linkNewWindow?(
											<a href={cleanPath(item.buttonLink)} target="_blank" rel="noreferrer">
												<Button bg={item.buttonBg}>
													{item.buttonTxt}
												</Button>
											</a>
										):(
											<Link to={cleanPath(item.buttonLink)}>
												<Button bg={item.buttonBg}>
													{item.buttonTxt}
												</Button>
											</Link>
										)}
									</PricingCard>
								)
							})
						}
					</PricingGrid>
				</ContentWrap>
			</div>
		)
	}
}

export default PricingTab