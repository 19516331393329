import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { cleanPath } from '../utils/path'

const ContentWrap = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, auto);

	margin-top: 23px;
	padding: 29px 31px;
	background: #F6F9F9;

	& h3 {
		font-size: 24px;
		margin: 4px 0 8px;
	}
	& p {
		font-size: 16px;
		line-height: 24px;
		color: #333333;
	}

	& button {
		width: 200px !important;
	}

	& div:nth-child(2) {
		text-align: right;
		& p {
			margin-top: 10px;
			color: #96BDD1;
		}
	}
	@media(max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 30px;
		& div {
			text-align: center !important;
		}
		& button {
			margin: auto;
			width: 100% !important;
		}
	}
`

const CustomSolution = ({ data }) => {
	return(
		<ContentWrap>
			<div>
				<label>{data.label}</label>
				<h3>{data.title}</h3>
				<p>{data.description}</p>
			</div>
			<div>
				{data.linkNewWindow?(
					<a href={cleanPath(data.buttonLink)} target="_blank" rel="noreferrer">
						<button className="is-second">
							{data.buttonTxt}
						</button>
					</a>
				):(
					<Link to={cleanPath(data.buttonLink)}>
						<button className="is-second">
							{data.buttonTxt}
						</button>
					</Link>
				)}
				<p>{data.buttonDescription}</p>
			</div>
		</ContentWrap>
	)
}

export default CustomSolution