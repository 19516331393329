import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo'
import { UsecaseHeroTriangle } from '../components/Triangle'
import ContentHero from '../components/ContentHero'
import CtaSection from '../components/CtaSection'
import PricingTab from '../components/PricingTab'
import CustomSolution from '../components/CustomSolution'

export const PricingPageTemplate = ({
  hero,
  monthlyPricing,
  annuallyPricing,
  customSolution,
}) => {
  return (
    <div>
      <ContentHero data={hero} />
      <UsecaseHeroTriangle />
      <section className="section">
        <div className="container">
          <PricingTab monthlyData={monthlyPricing} annuallyData={annuallyPricing} />
          <CustomSolution data={customSolution} />
        </div>
      </section>
      <CtaSection bgClassName="is-gray-bg" />
    </div>
  )
}


PricingPageTemplate.propTypes = {
  hero: PropTypes.object,
  monthlyPricing: PropTypes.object,
  annuallyPricing: PropTypes.object,
  customSolution: PropTypes.object,
}

const PricingPage = ({ data }) => {
  const { markdownRemark } = data

  return (
    <Layout>
      <SEO
        title={markdownRemark.frontmatter.seoTitle}
        description={markdownRemark.frontmatter.seoDescription}
      />
      <PricingPageTemplate
        hero={markdownRemark.frontmatter.hero}
        monthlyPricing={markdownRemark.frontmatter.monthlyPricing}
        annuallyPricing={markdownRemark.frontmatter.annuallyPricing}
        customSolution={markdownRemark.frontmatter.customSolution}
      />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PricingPage

export const pageQuery = graphql`
  query PricingPagesById($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
        }
        monthlyPricing {
          tab
          list {
            label
            popular
            price
            description
            features {
              item
            }
            buttonTxt
            buttonLink
            linkNewWindow
            buttonBg
          }
        }
        annuallyPricing {
          tab
          list {
            label
            popular
            price
            period
            description
            features {
              item
            }
            buttonTxt
            buttonLink
            linkNewWindow
            buttonBg
          }
        }
        customSolution {
          label
          title
          description
          buttonTxt
          buttonLink
          linkNewWindow
          buttonDescription
        }
      }
    }
  }
`
